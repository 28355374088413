<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="success"
      @click="toggleModal()"
    >
      <feather-icon
        icon="PlusIcon"
      />
      {{ $t('credits.direct_deposit') }}
    </b-button>
    <b-modal
      v-model="isDirectDepositModalActive"
      :title="`${$t('credits.direct_deposit')}`"
      hide-footer
    >
      <validation-observer
        ref="depositForm"
      >
        <form @submit.prevent="onSubmit">
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            label="เครดิตปัจจุบัน"
          >
            <b-form-input
              :value="player.credit || 0"
              readonly
            />
          </b-form-group>
          <b-form-group
            v-if="player.firstname"
            label-cols-sm="12"
            label-cols-lg="3"
            :label="`${$t('user.firstname')} ${$t('user.lastname')}`"
            label-for="name"
          >
            <b-form-input
              id="name"
              :value="`${player.firstname || ''} ${player.lastname || ''}`"
              readonly
            />
          </b-form-group>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('credits.credit_amount')"
            rules="required|min_value:1"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('credits.credit_amount')} *`"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model.number="transferForm.amount"
                type="text"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.remark')"
            rules="required"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('fields.remark')} *`"
              label-for="detail"
            >
              <b-form-textarea
                id="detail"
                v-model="transferForm.detail"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
          <b-form-group
            class="mb-3"
          >
            <b-form-radio-group
              id="is_master"
              v-model="transferForm.wallet"
              name="wallet"
            >
              <b-form-radio :value="0">
                กระเป๋าออโต้
              </b-form-radio>
              <b-form-radio :value="1">
                กระเป๋าคงเหลือ
              </b-form-radio>
              <b-form-radio :value="2">
                กระเป๋าสะสม
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <template v-if="!noPromo">
            <b-form-group
              abel-cols-sm="12"
              label-cols-lg="3"
              :label="$t('fields.direct_deposit_with_promotion')"
              label-for="with_promotion"
              class="d-flex align-items-center mb-0"
            >
              <b-input-group>
                <b-form-checkbox
                  v-model="transferForm.with_promotion"
                  name="with_promotion"
                />
              </b-input-group>
            </b-form-group>

            <small class="text-danger">
              ***กรณีเลือกรับโปรโมชั่น
              เงินทั้งหมดที่ถูกเติมตรงไปจะอยู่ในกระเป๋าเงินสะสม
            </small>
          </template>

          <template v-if="!noAttach">
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              label-for="detail"
              class="mt-4"
            >
              <UploadSlipModal @submit="onUpdateUploadedFile" />
              <b-form-text
                v-if="
                  credit.isUploadingSlipFileSuccess &&
                    transferForm.verify_image_url
                "
              >
                <span class="text-success">
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                  {{ $t('utils.status.uploaded') }}
                </span>
              </b-form-text>
            </b-form-group>
          </template>

          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="onCloseModal"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              :show="isDepositing"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from '@validations'

export default {
  components: {
    UploadSlipModal: () => import('./UploadSlipModal'),
  },
  props: {
    noBtn: {
      type: Boolean,
      default: false,
    },
    playerId: {
      type: String,
      default: '',
    },
    player: {
      type: Object,
      default: () => {},
    },
    noPromo: {
      type: Boolean,
      default: false,
    },
    noAttach: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      required,

      isDirectDepositModalActive: false,
      transferForm: {
        amount: 0,
        detail: '',
        wallet: 0,
      },
    }
  },
  computed: {
    ...mapState({
      isDepositing: (state) => state.player.isDepositingPlayerCredit,
    }),
    ...mapGetters(['uploadedFile']),
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['depositPlayerCredit']),
    setData() {
      this.transferForm = {
        amount: 0,
        detail: '',
        wallet: 0,
      }
    },
    toggleModal() {
      this.isDirectDepositModalActive = !this.isDirectDepositModalActive
    },
    onCloseModal() {
      this.isDirectDepositModalActive = false
    },
    onUpdateUploadedFile() {
      this.transferForm.verify_image_url = this.uploadedFile.url
    },
    async onSubmit() {
      this.$refs.depositForm.validate().then( async (success) => {
        if (success) {
          this.transferForm.amount = +this.transferForm.amount
          await this.depositPlayerCredit({
            playerId: this.playerId,
            data: this.transferForm,
            search: this.search
          })
          this.onCloseModal()
          this.$emit('finish')
          this.setData()
        }
      })
     
    },
  },
}
</script>
