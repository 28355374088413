var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.noBtn)?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.toggleModal()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('credits.direct_deposit'))+" ")],1):_vm._e(),_c('b-modal',{attrs:{"title":("" + (_vm.$t('credits.direct_deposit'))),"hide-footer":""},model:{value:(_vm.isDirectDepositModalActive),callback:function ($$v) {_vm.isDirectDepositModalActive=$$v},expression:"isDirectDepositModalActive"}},[_c('validation-observer',{ref:"depositForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":"เครดิตปัจจุบัน"}},[_c('b-form-input',{attrs:{"value":_vm.player.credit || 0,"readonly":""}})],1),(_vm.player.firstname)?_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":((_vm.$t('user.firstname')) + " " + (_vm.$t('user.lastname'))),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","value":((_vm.player.firstname || '') + " " + (_vm.player.lastname || '')),"readonly":""}})],1):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('credits.credit_amount'),"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":((_vm.$t('credits.credit_amount')) + " *"),"label-for":"amount"}},[_c('b-form-input',{attrs:{"id":"amount","type":"text","state":errors[0] ? false : null},model:{value:(_vm.transferForm.amount),callback:function ($$v) {_vm.$set(_vm.transferForm, "amount", _vm._n($$v))},expression:"transferForm.amount"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('fields.remark'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"12","label-cols-lg":"3","label":((_vm.$t('fields.remark')) + " *"),"label-for":"detail"}},[_c('b-form-textarea',{attrs:{"id":"detail","state":errors[0] ? false : null},model:{value:(_vm.transferForm.detail),callback:function ($$v) {_vm.$set(_vm.transferForm, "detail", $$v)},expression:"transferForm.detail"}})],1)]}}])}),_c('b-form-group',{staticClass:"mb-3"},[_c('b-form-radio-group',{attrs:{"id":"is_master","name":"wallet"},model:{value:(_vm.transferForm.wallet),callback:function ($$v) {_vm.$set(_vm.transferForm, "wallet", $$v)},expression:"transferForm.wallet"}},[_c('b-form-radio',{attrs:{"value":0}},[_vm._v(" กระเป๋าออโต้ ")]),_c('b-form-radio',{attrs:{"value":1}},[_vm._v(" กระเป๋าคงเหลือ ")]),_c('b-form-radio',{attrs:{"value":2}},[_vm._v(" กระเป๋าสะสม ")])],1)],1),(!_vm.noPromo)?[_c('b-form-group',{staticClass:"d-flex align-items-center mb-0",attrs:{"abel-cols-sm":"12","label-cols-lg":"3","label":_vm.$t('fields.direct_deposit_with_promotion'),"label-for":"with_promotion"}},[_c('b-input-group',[_c('b-form-checkbox',{attrs:{"name":"with_promotion"},model:{value:(_vm.transferForm.with_promotion),callback:function ($$v) {_vm.$set(_vm.transferForm, "with_promotion", $$v)},expression:"transferForm.with_promotion"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(" ***กรณีเลือกรับโปรโมชั่น เงินทั้งหมดที่ถูกเติมตรงไปจะอยู่ในกระเป๋าเงินสะสม ")])]:_vm._e(),(!_vm.noAttach)?[_c('b-form-group',{staticClass:"mt-4",attrs:{"label-cols-sm":"12","label-cols-lg":"3","label-for":"detail"}},[_c('UploadSlipModal',{on:{"submit":_vm.onUpdateUploadedFile}}),(
                _vm.credit.isUploadingSlipFileSuccess &&
                  _vm.transferForm.verify_image_url
              )?_c('b-form-text',[_c('span',{staticClass:"text-success"},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('utils.status.uploaded'))+" ")],1)]):_vm._e()],1)]:_vm._e(),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"light"},on:{"click":_vm.onCloseModal}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isDepositing,"rounded":"","spinner-small":""}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('buttons.confirm'))+" ")])],1)],1)],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }